import { CounterpartyCurrencyCode } from "./CounterpartyCurrencyCode";
import { CounterpartyTaxationType } from "./CounterpartyTaxationType";
import CounterpartyResponseModel from "./CounterpartyResponseModel";
import { AddressModel } from "../AddressModel";

export default class CounterpartyRequestModel {
  constructor(
    public name = "",
    public currencyCode: CounterpartyCurrencyCode = CounterpartyCurrencyCode.USD,
    public address: AddressModel = new AddressModel(),
    public websiteUrl = "",
    public note = "",
    public taxation?: CounterpartyTaxationType,
    public bankId?: number
  ) {}

  static of(
    model: CounterpartyResponseModel | CounterpartyRequestModel
  ): CounterpartyRequestModel {
    return new CounterpartyRequestModel(
      model.name,
      model.currencyCode,
      model.address,
      model.websiteUrl,
      model.note,
      model.taxation
    );
  }
}
