




























































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { cloneDeep, debounce } from "lodash";

import SidebarForm from "../SidebarForm.vue";

import CounterpartyRequestModel from "@/accounting-portal/models/counterparties/CounterpartyRequestModel";
import CounterpartyResponseModel from "@/accounting-portal/models/counterparties/CounterpartyResponseModel";
import { CounterpartyCurrencyCode } from "@/accounting-portal/models/counterparties/CounterpartyCurrencyCode";
import { CounterpartyTaxationType } from "@/accounting-portal/models/counterparties/CounterpartyTaxationType";
import BankResponseModel from "@/accounting-portal/models/banks/BankResponseModel";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component({
  components: {
    SidebarForm,
  },
})
export default class CounterpartyForm extends Vue {
  @Prop({ default: false }) value!: boolean;
  @Prop({ default: true }) isListPage!: boolean;
  @Prop() editingItem!: CounterpartyResponseModel | null;

  readonly REQUIRED_RULE = [
    ValidUtil.required(this.$lang("validation.required")),
  ];
  readonly CURRENCY_CODES = Object.values(CounterpartyCurrencyCode);
  readonly TAXATIONS = Object.values(CounterpartyTaxationType).map((value) => ({
    text: this.$lang(
      `accountingPortal.counterparties.taxation.${value.toLowerCase()}`
    ),
    value,
  }));
  localValue = new CounterpartyRequestModel();

  get visible(): boolean {
    return this.value;
  }

  set visible(value: boolean) {
    if (!value) {
      this.localValue = new CounterpartyRequestModel();
    }

    this.$emit("input", value);
  }

  get title(): string {
    return this.editingItem
      ? this.$lang("accountingPortal.counterparties.counterpartyDetailsTitle")
      : this.$lang("accountingPortal.counterparties.newCounterpartyTitle");
  }

  get saving(): boolean {
    return this.$store.state.counterpartyStore.isCounterpartySaving;
  }

  get errorMessages(): Array<string> {
    if (!this.$store.state.counterpartyStore.isCounterpartyNameUnique) {
      return [this.$lang("shared.errors.notUnique")];
    }

    return [];
  }

  get banks(): Array<{ text: string; value: number }> {
    return this.$store.state.bankStore.banks.map(
      ({ name, id }: BankResponseModel) => ({
        text: name,
        value: id,
      })
    );
  }

  @Watch("editingItem", { deep: true })
  watchEditingBank(value: CounterpartyResponseModel | null) {
    if (!value) {
      return;
    }

    this.localValue = CounterpartyRequestModel.of(cloneDeep(value));
  }

  @Watch("localValue.name", { deep: true })
  watchName = debounce((value: string) => {
    if (this.editingItem && value === this.editingItem.name) {
      this.$store.commit("setIsCounterpartyNameUnique", true);
      return;
    }

    this.$store.dispatch("checkCounterpartyNameUniqueness", value);
  }, 500);

  async handleSave(closeDialog: () => void) {
    if (this.editingItem) {
      await this.$store.dispatch("updateCounterparty", {
        id: this.editingItem.id,
        name: this.editingItem.name,
        payload: this.localValue,
        isForList: this.isListPage,
      });
    } else {
      const { id } = await this.$store.dispatch(
        "createCounterparty",
        this.localValue
      );

      this.$router.push({
        name: "counterparty",
        params: {
          counterpartyId: id,
        },
      });
    }

    closeDialog();
  }
}
